import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getUser } from '@codiwork/codi';

import { Button, Layout, Link, RequestState, Text } from 'ds';
import { apiTrack } from 'lib/analytics';
import { AUTH_PATH, CUSTOMER_OFFSITES_PATH, TERMS_PATH } from 'routes';
import { actions as authActions } from 'store/Auth';
import { actions as stbRequestActions } from 'store/STBRequest';
import { selectStbRequest } from 'store/STBRequest/selectors';
import { actions as userActions } from 'store/User';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  createShortTermBooking,
  createShortTermBookingRequest,
  isCreatableShortTermBooking,
  isCreatableShortTermBookingRequest
} from './utils';

interface Props {}

const STBSubmitRequest: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const stbRequest = useAppSelector(selectStbRequest);
  const user = useAppSelector(selectUser);
  const [requestState, setRequestState] = useState<RequestState>('ready');

  const createBooking = async () => {
    if (!isCreatableShortTermBooking(stbRequest) || !user) {
      dispatch(stbRequestActions.reset());
      return;
    }

    const { data } = await createShortTermBooking({ ...stbRequest, contact_id: user.id });
    dispatch(stbRequestActions.reset());

    const { data: updatedUser } = await getUser({ id: user.id });
    dispatch(userActions.setUser(updatedUser));

    history.push(`${CUSTOMER_OFFSITES_PATH}/${data.id}`);
  };

  return (
    <>
      <Button
        text="Submit request"
        size="md"
        type="primary"
        disabled={!isCreatableShortTermBookingRequest(stbRequest) || requestState === 'in_progress'}
        onClick={() => {
          if (isCreatableShortTermBookingRequest(stbRequest)) {
            apiTrack('STB Request Submitted', stbRequest);
            setRequestState('in_progress');
            createShortTermBookingRequest(stbRequest).then(({ data: _data }) => {
              if (!!user) {
                createBooking();
              } else {
                dispatch(authActions.setAuthFlowType('stbRequest'));
                history.push({ pathname: AUTH_PATH });
              }
            });
          }
        }}
        fullWidth
      />
      <Layout marginTop={12}>
        <Text size="body3" color="gray-500">
          By submitting this request, you agree to Codi’s{' '}
          <Link href={TERMS_PATH} target="_blank" display="inline">
            <Text size="body3" inline color="gray-500" semibold>
              Terms of Service
            </Text>
          </Link>{' '}
          and to pay the quoted price upon confirmation of this request.
        </Text>
      </Layout>
    </>
  );
};

export default STBSubmitRequest;
