export const ROOT_PATH = '/' as const;
export const APP_ROOT_PATH = '/app' as const;
export const HOME_PATH = `${APP_ROOT_PATH}` as const;
export const FIVE_HUNDRED_PATH = '/500' as const;
export const FOUR_ZERO_FOUR_PATH = '/404' as const;

// Shared UX
export const ACCOUNT_PATH = `${APP_ROOT_PATH}/account` as const;
export const ACCOUNT_PROFILE_PATH = `${ACCOUNT_PATH}/profile` as const;
export const NOTIFICATION_PREFERENCES_PATH = `${ACCOUNT_PATH}/preferences` as const;

// Public UX
export const ABOUT_US_PATH = '/about-us' as const;
export const ADD_PROPERTY_PATH = '/add-a-property' as const;
export const ANNUAL_REPORT_PATH = '/annual-report' as const;
export const AUTH_PATH = '/sign-in' as const;
export const BLOG_PATH = '/blog' as const;
export const BLOG_ARTICLE_PATH = `${BLOG_PATH}/:slug` as const;
export const CALENDAR_PATH = `${APP_ROOT_PATH}/calendar` as const;
export const CASE_STUDIES_PATH = '/case-studies' as const;
export const CASE_STUDY_PATH = `${CASE_STUDIES_PATH}/:slug` as const;
export const CASE_STUDY_SLUGS_WITH_TITLES = [
  {
    slug: 'taskrabbit',
    title: 'TaskRabbit Case Study',
    description: 'How TaskRabbit outsourced in-person collaboration for 200 employees using Codi Offsites'
  }
] as { title: string; slug: string; description: string }[];
export const CASE_STUDY_PATHS = CASE_STUDY_SLUGS_WITH_TITLES.map(({ slug }) => `${CASE_STUDIES_PATH}/${slug}` as const);
export const CASE_STUDY_SLUGS = [] as string[];
export const CATALOG_PATH = '/catalog' as const;
export const CITY_PAGE_PATH = '/cities' as const;
export const CITY_PAGE_SLUG_PATH = `${CITY_PAGE_PATH}/:slug` as const;
export const COMMERCIAL_REAL_ESTATE_PATH = '/commercial-real-estate' as const;
export const COMPARE_PATH = '/compare' as const;
export const COMPARE_DETAIL_SLUG_PATH = `${COMPARE_PATH}/:slug` as const;
export const HOW_IT_WORKS_PATH = '/how-it-works' as const;
export const LISTING_PATH = '/office-space' as const;
export const LISTING_SLUG_PATH = `${LISTING_PATH}/:slug` as const;
export const LOCATION_PATH = '/locations' as const;
export const LOCATION_SLUG_PATH = `${LOCATION_PATH}/:country/:region/:city` as const;
export const NOTIFICATION_PREFERENCES_TOKEN_PATH = '/notification_preferences/:token' as const;
export const OFFICE_REQUEST_PATH = '/request-an-office' as const;
export const OFFSITES_PATH = '/offsites' as const;
export const PLAYBOOK_PATH = '/playbook' as const;
export const PRESS_PATH = '/press' as const;
export const PRIVACY_PATH = '/privacy' as const;
export const REFERRAL_LANDING_CODE_PATH = '/i/:referralCode' as const;
export const REFERRAL_LANDING_PATH = '/i' as const;
export const SEARCH_PATH = '/office-space/search' as const;
export const SEARCH_PATH_DEPRECATED = '/search' as const;
export const SEARCH_ENTRY_PATH = '/s/office-space' as const;
export const SITEMAP_PATH = '/sitemap' as const;
export const STB_REQUEST_PATH = '/request-booking' as const;
export const STB_REQUEST_SLUG_PATH = `${STB_REQUEST_PATH}/:id` as const;
export const TERMS_PATH = '/terms' as const;
export const SF_TECH_WEEK_PATH = '/sf-tech-week' as const;
export const NYC_TECH_WEEK_PATH = '/nyc-tech-week' as const;
export const TESTIMONIALS_PATH = '/testimonials' as const;
export const BROKERS_PATH = '/brokers' as const;

// Admin UX
export const ADMIN_PATH = '/admin' as const;
export const ADMIN_CUSTOMER_PATH = `${ADMIN_PATH}/customers/:customerId` as const;
export const ADMIN_CUSTOMER_PLAN_PATH = `${ADMIN_PATH}/customers/:customerId/plans/:planId` as const;
export const ADMIN_CUSTOMERS_PATH = `${ADMIN_PATH}/customers` as const;
export const ADMIN_TOOLS_PATH = `${ADMIN_PATH}/tools` as const;
export const ADMIN_DEVELOPER_TOOLS_PATH = `${ADMIN_TOOLS_PATH}/developer-tools` as const;
export const ADMIN_DOCUMENT_TYPE_PATH = `${ADMIN_TOOLS_PATH}/documents/:typeId` as const;
export const ADMIN_DOCUMENT_TYPES_PATH = `${ADMIN_TOOLS_PATH}/documents` as const;
export const ADMIN_FEATURES_PATH = `${ADMIN_TOOLS_PATH}/features` as const;
export const ADMIN_PRODUCTS_PATH = `${ADMIN_PATH}/products` as const;
export const ADMIN_PRODUCT_SLUG_PATH = `${ADMIN_PRODUCTS_PATH}/:id` as const;
export const ADMIN_MAP_PATH = `${ADMIN_PATH}/map` as const;
export const ADMIN_MORE_PATH = `${ADMIN_PATH}/more` as const;
export const ADMIN_PAYOUTS_DRY_RUN_PATH = `${ADMIN_TOOLS_PATH}/dry-run` as const;
export const ADMIN_PAYOUTS_PATH = `${ADMIN_PATH}/payouts` as const;
export const ADMIN_PAYOUT_JOBS_PATH = `${ADMIN_PATH}/payouts/jobs` as const;
export const ADMIN_PAYOUT_JOB_PATH = `${ADMIN_PATH}/payouts/jobs/:payoutJobId` as const;
export const ADMIN_PAYOUT_PATH = `${ADMIN_PATH}/payouts/:payoutId` as const;
export const ADMIN_TASKS_PATH = `${ADMIN_PATH}/tasks` as const;
export const ADMIN_SEARCH_ENTRIES_PATH = `${ADMIN_TOOLS_PATH}/search-entries` as const;
export const ADMIN_SEARCH_ENTRIES_SLUG_PATH = `${ADMIN_TOOLS_PATH}/search-entries/:guid` as const;
export const ADMIN_SEO_PATH = `${ADMIN_TOOLS_PATH}/seo` as const;
export const ADMIN_SHORT_TERM_BOOKINGS_PATH = `${ADMIN_PATH}/short-term-bookings` as const;
export const ADMIN_SHORT_TERM_BOOKING_SLUG_PATH = `${ADMIN_PATH}/short-term-bookings/:id` as const;
export const ADMIN_USER_PATH = `${ADMIN_PATH}/users/:id` as const;
export const ADMIN_USERS_PATH = `${ADMIN_PATH}/users` as const;
export const ADMIN_CHATBOT_PATH = `${ADMIN_PATH}/chatbot` as const;
export const ADMIN_CHATBOT_THREADS_PATH = `${ADMIN_CHATBOT_PATH}/threads` as const;
export const ADMIN_SUPPORT_PATH = `${ADMIN_PATH}/support` as const;
export const ADMIN_SUPPORT_TICKET_PATH = `${ADMIN_SUPPORT_PATH}/:id` as const;
export const ADMIN_CHATBOT_THREAD_SLUG_PATH = `${ADMIN_CHATBOT_THREADS_PATH}/:id` as const;
export const ADMIN_WORKSPACE_PATH = `${ADMIN_PATH}/workspaces/:workspaceId` as const;
export const ADMIN_WORKSPACES_PATH = `${ADMIN_PATH}/workspaces` as const;
export const ADMIN_WORKSPACE_USER_PATH = `${ADMIN_TOOLS_PATH}/workspace_users/:licensorId` as const; // TODO: rename
export const ADMIN_LICENSORS_PATH = `${ADMIN_PATH}/licensors` as const;
export const ADMIN_LICENSOR_PATH = `${ADMIN_PATH}/licensors/:licensorId` as const;
export const ADMIN_LICENSOR_LICENSE_AGREEMENT_PATH = `${ADMIN_PATH}/licensors/:licensorId/license_agreements/:licenseAgreementId` as const;
export const ADMIN_LICENSOR_PAYOUT_ACCOUNT_PATH = `${ADMIN_PATH}/licensors/:licensorId/payout_account` as const;
export const ADMIN_LOCATIONS_PATH = `${ADMIN_TOOLS_PATH}/locations` as const;
export const ADMIN_ROUND_ROBIN_GROUPS_PATH = `${ADMIN_TOOLS_PATH}/round-robin` as const;
export const ADMIN_REFERRAL_CONFIGS_PATH = `${ADMIN_TOOLS_PATH}/referral-configs` as const;
export const ADMIN_INCLUDED_SERVICES_PATH = `${ADMIN_TOOLS_PATH}/included-services` as const;
export const ADMIN_BEVERAGE_SERVICE_PATH = `${ADMIN_TOOLS_PATH}/beverage-service` as const;
export const ADMIN_RESTOCKING_PATH = `${ADMIN_TOOLS_PATH}/restocking` as const;
export const ADMIN_PRICING_PATH = `${ADMIN_TOOLS_PATH}/pricing` as const;
export const ADMIN_ANALYTICS_PATH = `${ADMIN_TOOLS_PATH}/analytics` as const;
export const ADMIN_VENDORS_PATH = `${ADMIN_PATH}/vendors` as const;
export const ADMIN_VENDOR_PATH = `${ADMIN_VENDORS_PATH}/:vendorId` as const;

export const QA_LINK = '/qa-link' as const;

// Licensor UX
export const PROPERTIES_PATH = `${APP_ROOT_PATH}/properties` as const;
export const PROPERTIES_LISTINGS_PATH = `${PROPERTIES_PATH}/listings` as const;
export const PROPERTIES_LISTING_SLUG_PATH = `${PROPERTIES_LISTINGS_PATH}/:workspaceId` as const;
export const PROPERTIES_ACCOUNTS_PATH = `${PROPERTIES_PATH}/accounts` as const;
export const PROPERTIES_PAYOUTS_PATH = `${PROPERTIES_PATH}/payouts` as const;
export const PROPERTIES_PAYOUT_SUMMARY_PATH = `${PROPERTIES_PAYOUTS_PATH}/:payoutId` as const;

// Customer UX
export const DASHBOARD_PATH = `${APP_ROOT_PATH}/dashboard` as const;
export const TENANT_PAYMENT_METHODS_PATH = `${APP_ROOT_PATH}/payment-methods` as const;
export const AGREEMENTS_PATH = `${DASHBOARD_PATH}/agreements` as const;
export const AGREEMENTS_SLUG_PATH = `${DASHBOARD_PATH}/agreements/:planId` as const;
export const CUSTOMER_REQUESTS_PATH = `${APP_ROOT_PATH}/requests` as const;
export const CUSTOMER_OFFICE_REQUESTS_PATH = `${CUSTOMER_REQUESTS_PATH}/offices` as const;
export const CUSTOMER_OFFICE_REQUESTS_SLUG_PATH = `${CUSTOMER_OFFICE_REQUESTS_PATH}/:workspaceId` as const;
export const CUSTOMER_REQUESTS_SLUG_PATH = `${CUSTOMER_REQUESTS_PATH}/:id` as const;
export const OFFICES_PATH = `${APP_ROOT_PATH}/offices` as const;
export const OFFICES_SLUG_PATH = `${OFFICES_PATH}/:workspaceId` as const;
export const PAYMENTS_PATH = `${APP_ROOT_PATH}/payments` as const;
export const PAYMENT_SUMMARY_SLUG_PATH = `${PAYMENTS_PATH}/:stripeInvoiceId` as const;
export const CUSTOMER_OFFICE_REQUEST_PATH = `${APP_ROOT_PATH}/offices/requests` as const;
export const CUSTOMER_OFFICE_REQUEST_ID_PATH = `${CUSTOMER_OFFICE_REQUEST_PATH}/:id` as const;
export const UPCOMING_PAYMENT_PATH = `${DASHBOARD_PATH}/upcoming` as const;
export const REFERRALS_PATH = `${APP_ROOT_PATH}/referrals` as const;
export const VENDOR_TASKS_PATH = `${APP_ROOT_PATH}/tasks` as const;
export const CUSTOMER_OFFSITES_PATH = `${APP_ROOT_PATH}/offsites` as const;
export const CUSTOMER_OFFSITES_SLUG_PATH = `${CUSTOMER_OFFSITES_PATH}/:id` as const;
export const CUSTOMER_SERVICES_PATH = `${APP_ROOT_PATH}/services` as const;
export const CUSTOMER_CLEANING_SLUG_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/cleaning` as const;
export const CUSTOMER_CLEANING_REDIRECT_PATH = `${CUSTOMER_SERVICES_PATH}/cleaning` as const;
export const CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId` as const;
export const CUSTOMER_FURNITURE_SLUG_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/furniture` as const;
export const CUSTOMER_PANTRY_SLUG_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/pantry` as const;
export const ADD_ON_ORDERS_REDIRECT_PATH = `${APP_ROOT_PATH}/orders` as const;
export const ADD_ON_ORDERS_REDIRECT_SLUG_PATH = `${ADD_ON_ORDERS_REDIRECT_PATH}/:id` as const;
export const CUSTOMER_ORDERS_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/orders` as const;
export const CUSTOMER_ADD_FURNITURE_SLUG_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/furniture/add` as const;
export const CUSTOMER_EDIT_CLEANING_SCHEDULE_PATH = `${CUSTOMER_SERVICES_PATH}/:workspaceId/cleaning-schedule/edit` as const;
export const MEMBERSHIP_PATH = `${APP_ROOT_PATH}/membership` as const;
export const MEMBERSHIP_PLAN_PATH = `${MEMBERSHIP_PATH}/plan` as const;
export const MEMBERSHIP_PLAN_SLUG_PATH = `${MEMBERSHIP_PLAN_PATH}/:planId` as const;
export const MEMBERSHIP_SETTINGS_PATH = `${MEMBERSHIP_PATH}/settings` as const;
export const MEMBERSHIP_ADD_ONS_SLUG_PATH = `${MEMBERSHIP_PLAN_SLUG_PATH}/add-ons` as const;
export const CUSTOMER_SUPPORT_PATH = `${APP_ROOT_PATH}/support` as const;
export const CUSTOMER_SUPPORT_SLUG_PATH = `${CUSTOMER_SUPPORT_PATH}/:id` as const;

export const PATHS = [
  ...CASE_STUDY_PATHS,
  ABOUT_US_PATH,
  ACCOUNT_PATH,
  ADD_ON_ORDERS_REDIRECT_PATH,
  ADD_ON_ORDERS_REDIRECT_SLUG_PATH,
  ADD_PROPERTY_PATH,
  ADMIN_CHATBOT_PATH,
  ADMIN_CHATBOT_THREADS_PATH,
  ADMIN_PATH,
  ADMIN_SUPPORT_PATH,
  ADMIN_SUPPORT_TICKET_PATH,
  AGREEMENTS_PATH,
  AGREEMENTS_SLUG_PATH,
  ANNUAL_REPORT_PATH,
  AUTH_PATH,
  BLOG_ARTICLE_PATH,
  BLOG_PATH,
  BROKERS_PATH,
  CALENDAR_PATH,
  CASE_STUDY_PATH,
  CATALOG_PATH,
  CITY_PAGE_SLUG_PATH,
  COMMERCIAL_REAL_ESTATE_PATH,
  COMPARE_DETAIL_SLUG_PATH,
  COMPARE_PATH,
  VENDOR_TASKS_PATH,
  CUSTOMER_ADD_FURNITURE_SLUG_PATH,
  CUSTOMER_CLEANING_REDIRECT_PATH,
  CUSTOMER_CLEANING_SLUG_PATH,
  CUSTOMER_EDIT_CLEANING_SCHEDULE_PATH,
  CUSTOMER_FURNITURE_SLUG_PATH,
  CUSTOMER_OFFICE_REQUEST_ID_PATH,
  CUSTOMER_OFFICE_REQUEST_PATH,
  CUSTOMER_OFFICE_REQUESTS_SLUG_PATH,
  CUSTOMER_OFFSITES_PATH,
  CUSTOMER_OFFSITES_SLUG_PATH,
  CUSTOMER_ORDERS_PATH,
  CUSTOMER_PANTRY_SLUG_PATH,
  CUSTOMER_REQUESTS_PATH,
  CUSTOMER_REQUESTS_SLUG_PATH,
  CUSTOMER_SERVICES_AND_AMENITIES_SLUG_PATH,
  CUSTOMER_SUPPORT_PATH,
  CUSTOMER_SUPPORT_SLUG_PATH,
  DASHBOARD_PATH,
  FIVE_HUNDRED_PATH,
  FOUR_ZERO_FOUR_PATH,
  HOME_PATH,
  HOW_IT_WORKS_PATH,
  LISTING_PATH,
  LISTING_SLUG_PATH,
  LOCATION_SLUG_PATH,
  MEMBERSHIP_ADD_ONS_SLUG_PATH,
  MEMBERSHIP_PATH,
  MEMBERSHIP_PLAN_PATH,
  MEMBERSHIP_PLAN_SLUG_PATH,
  MEMBERSHIP_SETTINGS_PATH,
  NOTIFICATION_PREFERENCES_TOKEN_PATH,
  NYC_TECH_WEEK_PATH,
  OFFICE_REQUEST_PATH,
  OFFICES_PATH,
  OFFICES_SLUG_PATH,
  OFFSITES_PATH,
  PAYMENT_SUMMARY_SLUG_PATH,
  PAYMENTS_PATH,
  PLAYBOOK_PATH,
  PRESS_PATH,
  PRIVACY_PATH,
  PROPERTIES_ACCOUNTS_PATH,
  PROPERTIES_LISTING_SLUG_PATH,
  PROPERTIES_LISTINGS_PATH,
  PROPERTIES_PATH,
  PROPERTIES_PAYOUT_SUMMARY_PATH,
  PROPERTIES_PAYOUTS_PATH,
  QA_LINK,
  REFERRAL_LANDING_CODE_PATH,
  REFERRAL_LANDING_PATH,
  REFERRALS_PATH,
  ROOT_PATH,
  SEARCH_ENTRY_PATH,
  SEARCH_PATH_DEPRECATED,
  SEARCH_PATH,
  SF_TECH_WEEK_PATH,
  SITEMAP_PATH,
  STB_REQUEST_PATH,
  STB_REQUEST_SLUG_PATH,
  TENANT_PAYMENT_METHODS_PATH,
  TERMS_PATH,
  TESTIMONIALS_PATH
] as const;

export const REDIRECTS = [];

export type Path = typeof PATHS[number];
