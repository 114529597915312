import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Layout } from 'ds';
import { CUSTOMER_SUPPORT_PATH } from 'routes';
import { selectCustomerSupportTicketByInteractionId } from 'store/Chatbot/selectors';
import { useAppSelector } from 'store/hooks';
import { ChatbotIntentProps } from 'ux/Chatbot/types';
import { shouldAnimateIn } from 'ux/Chatbot/utils';

import ChatbotCreateSupportTicket from '../ChatbotCreateSupportTicket';
import ChatbotViewSupportTicket from '../ChatbotViewSupportTicket';
import { LLMResponse } from '../LLMResponse';

interface Props extends ChatbotIntentProps {}

const SupportTicket: React.FC<Props> = ({ chatbotInteraction, threadId }) => {
  const history = useHistory();
  const supportTicket = useAppSelector(selectCustomerSupportTicketByInteractionId(chatbotInteraction.id));
  const { llm_response, resolved_at } = chatbotInteraction;

  const animateIn = shouldAnimateIn(chatbotInteraction);

  useEffect(() => {
    if (!!supportTicket?.id) {
      history.push(`${CUSTOMER_SUPPORT_PATH}/${supportTicket?.id}`);
    }
  }, [history, supportTicket?.id]);

  return (
    <>
      <LLMResponse llmResponse={llm_response} animateIn={animateIn} />
      {resolved_at && supportTicket ? (
        <Layout marginTop={16}>
          <ChatbotViewSupportTicket supportTicket={supportTicket} />
        </Layout>
      ) : (
        <Layout marginTop={16}>
          <ChatbotCreateSupportTicket
            chatbotInteraction={chatbotInteraction}
            threadId={threadId}
            animateIn={animateIn}
            animationDelay={300}
          />
        </Layout>
      )}
    </>
  );
};

export default SupportTicket;
