import { PropsWithChildren, forwardRef, useContext, useEffect, useRef, useState } from 'react';
import { usePreviousValue } from 'beautiful-react-hooks';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

import { app, media } from 'context';
import { ANIMATION_DURATION, BOTTOM_SHEET_TOP } from 'ds/constants';
import useKeyDown from 'ds/helpers/useKeyDown';
import { actions as uiActions } from 'store/UI';
import { selectUIState } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ModalContainer from './ModalContainer';
import { ModalProps } from './types';
import BottomSheet from '../BottomSheet';
import Layout from '../Layout';
import { getRootElement, lockScreenScroll, unlockScreenScroll } from '../utils';

const Modal = forwardRef<HTMLDivElement, PropsWithChildren<ModalProps>>((props, ref) => {
  const {
    onClose,
    overflow,
    closeDisabled,
    isFullScreen = false,
    isVisible,
    hasBottomSheetPaddingX,
    bottomSheetGutterTop,
    children,
    scrimBackground,
    footer,
    scrollable = true,
    animate = true,
    header,
    showCloseButton = true,
    handleKeyboardFocus,
    headerProps,
    scrollShadow = true,
    headerAlign,
    bottomSheetAnimation,
    hasFooterBorder = true,
    topLeft,
    reverseClosePosition,
    verticallyCenterContent,
    bottomSheetZIndexPosition,
    showLoadingState,
    bottomSheetIsRounded,
    zIndexPosition = 0,
    bottomSheetContainerProps,
    closeOnEscapePress = true,
    bottomSheetBorderRadius,
    handleLockScreenScroll = true,
    bottomSheetBackgroundColor,
    hasBottomSheetFooterPadding,
    containerRef
  } = props;
  const { xs, isMobile } = useContext(media);
  const isBottomSheet = props.isBottomSheet || xs;
  const lastScrollY = useRef<number>(window.scrollY);
  const [state, setState] = useState<'entering' | 'visible' | 'initialized-visible' | 'hiding' | 'hidden'>(
    isVisible ? 'initialized-visible' : 'hidden'
  );
  const previousIsVisible = usePreviousValue(isVisible);
  const { windowHeight } = useContext(app);
  const rootRef = useRef<HTMLElement | null>();
  const dispatch = useAppDispatch();
  const uiState = useAppSelector(selectUIState);

  useEffect(() => {
    rootRef.current = getRootElement();
  }, []);

  const hideBottomSheet = () => {
    if (closeDisabled) return;

    onClose();
  };

  useKeyDown((e: KeyboardEvent) => {
    if (!isVisible) return;

    if (!closeOnEscapePress) return;

    if (e.key === 'Escape') {
      onClose();
    }
  });

  useEffect(() => {
    const rootElement = getRootElement();

    if (!rootElement) return;

    if (isVisible && state === 'initialized-visible') return;

    const isHandlingScrollLock = !(props.isSideDrawer && !isMobile) && handleLockScreenScroll;

    if (isVisible) {
      if (!previousIsVisible) {
        lastScrollY.current = window.scrollY;
      }

      if (isHandlingScrollLock) {
        lockScreenScroll();
      }

      setState('entering');
      setTimeout(() => {
        setState('visible');
      }, 20);
    } else if (previousIsVisible) {
      setState('hiding');
      rootElement.style.overflowY = 'unset';

      if (isHandlingScrollLock) {
        unlockScreenScroll();
      }

      if (xs) {
        window.scrollTo(0, lastScrollY.current);
        if (!uiState.bottomNavigationIsVisible) {
          dispatch(uiActions.updateUIState({ bottomNavigationIsVisible: true }));
        }
      }

      setTimeout(() => {
        setState('hidden');
      }, ANIMATION_DURATION);
    }
  }, [xs, isVisible, previousIsVisible, scrollable]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      unlockScreenScroll();

      if (xs && !uiState.bottomNavigationIsVisible) {
        dispatch(uiActions.updateUIState({ bottomNavigationIsVisible: true }));
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isBottomSheet) {
    return (
      <BottomSheet
        isVisible={isVisible}
        state={state}
        onClickOutside={hideBottomSheet}
        overflowVisible={overflow === 'visible'}
        isFullScreen={isFullScreen}
        onClose={hideBottomSheet}
        gutterTop={
          isFullScreen ? 0 : typeof bottomSheetGutterTop === 'number' ? bottomSheetGutterTop : BOTTOM_SHEET_TOP
        }
        borderRadius={bottomSheetBorderRadius}
        topLeft={topLeft}
        paddingX={hasBottomSheetPaddingX ? 24 : 0}
        scrimBackground={scrimBackground}
        header={header}
        footer={footer}
        animate={animate}
        showCloseButton={showCloseButton}
        headerProps={headerProps}
        handleKeyboardFocus={handleKeyboardFocus}
        scrollShadow={scrollShadow}
        animation={bottomSheetAnimation || 'slide-up'}
        headerAlign={headerAlign}
        hasFooterBorder={hasFooterBorder}
        reverseClosePosition={reverseClosePosition}
        verticallyCenterContent={verticallyCenterContent}
        zIndexPosition={bottomSheetZIndexPosition}
        showLoadingState={showLoadingState}
        isRounded={bottomSheetIsRounded}
        containerProps={bottomSheetContainerProps}
        backgroundColor={bottomSheetBackgroundColor}
        containerRef={containerRef}
        hasFooterPadding={hasBottomSheetFooterPadding}
      >
        {children}
      </BottomSheet>
    );
  }

  const portalElement = rootRef.current || getRootElement();

  if (props.isSideDrawer) {
    return portalElement
      ? ReactDOM.createPortal(
          <>
            <Layout
              position="fixed"
              top={0}
              right={0}
              height={windowHeight}
              __className={classNames('SideDrawer-scrim', `is-${state}`)}
              {...(props.showSideDrawerScrim ? { background: 'rgba(0,0,0,.45)', width: '100%' } : {})}
            ></Layout>
            <ModalContainer
              {...props}
              scrollContainerRef={ref}
              className={classNames('SideDrawer-content', `is-${state}`)}
            />
          </>,
          portalElement
        )
      : null;
  }

  return portalElement
    ? ReactDOM.createPortal(
        <Layout
          position="fixed"
          top={0}
          left={0}
          width="100%"
          height={windowHeight}
          background="rgba(0,0,0,.45)"
          __className={classNames(
            'ModalContainer',
            `is-${state}`,
            isFullScreen ? 'is-full-screen' : 'is-modal',
            `is-z-index-${zIndexPosition}`
          )}
          align="center"
          justify="center"
        >
          <ModalContainer {...props} scrollContainerRef={ref} />
        </Layout>,
        portalElement
      )
    : null;
});

export default Modal;
