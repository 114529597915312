import React, { useState } from 'react';
import classNames from 'classnames';

import { Animate, Clickable, Icon, Layout, PurpleGradientCircle, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_NEW_SCREEN } from './constants';

interface Props {
  animateIn?: boolean;
}

const ChatbotAskButton: React.FC<Props> = ({ animateIn }) => {
  const dispatch = useAppDispatch();
  const navigateToNewThread = () => dispatch(actions.pushScreen({ value: CHATBOT_NEW_SCREEN, useAnimation: true }));
  const [animationState, setAnimationState] = useState<'idle' | 'in-progress' | 'done'>('idle');

  return (
    <Clickable
      onClick={() => {
        setAnimationState('in-progress');
        setTimeout(() => {
          navigateToNewThread();
          setAnimationState('done');
        }, 300);
      }}
      style={{ borderRadius: 8, position: 'relative' }}
      fullWidth
    >
      <Animate
        borderRadius={8}
        boxShadow="2px 0 22px 0 rgba(34.1176, 28.6275, 42.7451, 0.1200)"
        paddingX={16}
        paddingY={16}
        justify="space-between"
        disableAnimation={!animateIn}
        animation="fade-up"
        duration={300}
        delay={50}
        align="center"
        color="white"
      >
        <div className={classNames('ChatbotAskButton-text', `animation-${animationState}`)}>
          <Text size="body-sm" semibold>
            Make a request
          </Text>
        </div>
        <Layout
          gap={12}
          align="center"
          flex
          __className={classNames('ChatbotAskButton-icon', `animation-${animationState}`)}
        >
          <PurpleGradientCircle dimension={32} />
          <Icon stroke={2} name="rightChevron" size="xs" />
        </Layout>
      </Animate>
      <Layout
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height={64}
        __className={classNames('ChatbotAskButton-animation-scrim', `animation-${animationState}`)}
      />
    </Clickable>
  );
};

export default ChatbotAskButton;
