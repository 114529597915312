import React, { useRef, useState } from 'react';

import DateRange from './DateRange';
import { HEADER_INPUT_SIZE } from './constants';
import { FilterState, Filters, Row, SetFilter } from './types';
import Card from '../Card';
import Checkbox from '../Checkbox';
import Layout from '../Layout';
import Text from '../Text';
import TextButton from '../TextButton';
import { HEADER_Z_INDEX } from '../constants';
import { useOnClickOutside } from '../helpers';
import MoneyInput from '../inputs/MoneyInput';
import NumberInput from '../inputs/NumberInput';
import TextInput from '../inputs/TextInput';

interface Props<T> {
  searchInputOpen: boolean;
  filterInputOpen: boolean;
  dateRangeOpen: boolean;
  moneyRangeOpen: boolean;
  numberRangeOpen: boolean;
  filters: Filters<T>;
  filter?: FilterState;
  setFilter: SetFilter;
  alignRight: boolean;
  headerWidth: number;
  hideFilterInputs: VoidFunction;
}

const FilterControls = <T extends Row>({
  searchInputOpen,
  filterInputOpen,
  dateRangeOpen,
  moneyRangeOpen,
  numberRangeOpen,
  filter,
  filters,
  setFilter,
  alignRight,
  headerWidth,
  hideFilterInputs
}: Props<T>) => {
  const oneOfValues = filter?.oneOf || [];

  const searchFilterRef = useRef<HTMLDivElement>(null);
  const [calendarStartElement, setCalendarStartElement] = useState<HTMLDivElement | null>(null);
  const [calendarEndElement, setCalendarEndElement] = useState<HTMLDivElement | null>(null);

  useOnClickOutside(searchFilterRef, event => {
    if (
      (calendarStartElement && calendarStartElement.contains(event.target as HTMLElement)) ||
      (calendarEndElement && calendarEndElement.contains(event.target as HTMLElement))
    ) {
      return;
    }
    hideFilterInputs();
  });

  if (searchInputOpen) {
    return (
      <Layout
        __ref={searchFilterRef}
        position="absolute"
        zIndex={HEADER_Z_INDEX}
        {...(alignRight ? { right: headerWidth } : { left: headerWidth + 8 })}
        top={0}
        __className="Table-textFilter"
        paddingLeft={16}
      >
        <TextInput
          label="Search"
          value={filter?.substring}
          size={HEADER_INPUT_SIZE}
          onChange={({ value }) => {
            setFilter({ substring: value });
          }}
          onClear={() => setFilter({ substring: '' })}
          autoFocus
        />
      </Layout>
    );
  } else if (filterInputOpen && filters.oneOf) {
    return (
      <Layout
        __ref={searchFilterRef}
        position="absolute"
        zIndex={HEADER_Z_INDEX}
        {...(alignRight ? { right: headerWidth - 20 } : { left: headerWidth - 16 })}
        top={-20}
        padding={20}
      >
        <Card size="sm">
          {filters.oneOf.options.map(({ label, value }, index) => (
            <Layout key={value.toString()} marginTop={index === 0 ? undefined : 8}>
              <Checkbox
                value={oneOfValues.includes(value) ? 'checked' : 'off'}
                label={label}
                onChange={({ value: checkboxValue }) => {
                  if (checkboxValue === 'checked') {
                    setFilter({ oneOf: [...oneOfValues, value] });
                  } else {
                    setFilter({ oneOf: oneOfValues.filter(v => v !== value) });
                  }
                }}
                size={HEADER_INPUT_SIZE}
              />
            </Layout>
          ))}
          <Layout marginTop={8} direction="row" justify="space-between">
            <TextButton
              scale
              textSize="body3"
              text="Clear"
              color="gray-600"
              paddingY={false}
              onClick={() => setFilter({ oneOf: [] })}
            />
            <TextButton
              scale
              textSize="body3"
              text="Select all"
              color="blue-500"
              paddingY={false}
              onClick={() => setFilter({ oneOf: filters?.oneOf?.options.map(o => o.value) || [] })}
            />
          </Layout>
        </Card>
      </Layout>
    );
  } else if (dateRangeOpen) {
    return (
      <Layout
        __ref={searchFilterRef}
        position="absolute"
        zIndex={HEADER_Z_INDEX}
        {...(alignRight ? { right: headerWidth - 20 } : { left: headerWidth - 16 })}
        top={-8}
        __className="Table-dateRangeFilter"
        padding={20}
      >
        <DateRange
          startDate={filter?.startDate}
          endDate={filter?.endDate}
          reset={() => setFilter({ startDate: undefined, endDate: undefined })}
          setStartDate={startDate => setFilter({ startDate: startDate || undefined, endDate: filter?.endDate })}
          setEndDate={endDate => setFilter({ startDate: filter?.startDate, endDate: endDate || undefined })}
          setCalendarStartElement={setCalendarStartElement}
          setCalendarEndElement={setCalendarEndElement}
          placement={alignRight ? 'bottom-end' : 'bottom'}
        />
      </Layout>
    );
  } else if (moneyRangeOpen) {
    return (
      <Layout
        __ref={searchFilterRef}
        position="absolute"
        zIndex={HEADER_Z_INDEX}
        {...(alignRight ? { right: headerWidth } : { left: headerWidth - 16 })}
        top={0}
        __className="Table-numberRangeFilter"
        padding={4}
        direction="row"
        align="center"
        color="white"
      >
        <MoneyInput
          label="min"
          required={false}
          value={filter?.minValue}
          size={HEADER_INPUT_SIZE}
          onChange={({ value }) => {
            setFilter({ minValue: value, maxValue: filter?.maxValue });
          }}
          onClear={() => setFilter({ minValue: undefined, maxValue: filter?.maxValue })}
          autoFocus
        />
        <Text size="body2" scale>
          {' '}
          -{' '}
        </Text>
        <MoneyInput
          label="max"
          required={false}
          value={filter?.maxValue}
          size={HEADER_INPUT_SIZE}
          onChange={({ value }) => {
            setFilter({ minValue: filter?.minValue, maxValue: value });
          }}
          onClear={() => setFilter({ minValue: filter?.minValue, maxValue: undefined })}
        />
      </Layout>
    );
  } else if (numberRangeOpen) {
    return (
      <Layout
        __ref={searchFilterRef}
        position="absolute"
        zIndex={HEADER_Z_INDEX}
        {...(alignRight ? { right: headerWidth } : { left: headerWidth - 40 })}
        top={0}
        __className="Table-numberRangeFilter"
        padding={4}
        direction="row"
        align="center"
        color="white"
      >
        <NumberInput
          label="min"
          required={false}
          value={filter?.minValue}
          size={HEADER_INPUT_SIZE}
          onChange={({ value }) => {
            setFilter({ minValue: value, maxValue: filter?.maxValue });
          }}
          onClear={() => setFilter({ minValue: undefined, maxValue: filter?.maxValue })}
          autoFocus
        />
        <Text size="body2" scale>
          {' '}
          -{' '}
        </Text>
        <NumberInput
          label="max"
          required={false}
          value={filter?.maxValue}
          size={HEADER_INPUT_SIZE}
          onChange={({ value }) => {
            setFilter({ minValue: filter?.minValue, maxValue: value });
          }}
          onClear={() => setFilter({ minValue: filter?.minValue, maxValue: undefined })}
        />
      </Layout>
    );
  } else {
    return null;
  }
};

export default FilterControls;
