import { DASHBOARD_PATH, LISTING_PATH } from 'routes';

type PathToRedirect = {
  from: string;
  to: string;
  exact?: boolean;
  redirectId?: boolean;
};

export const pathsToRedirect: PathToRedirect[] = [
  {
    from: '/coworking-space',
    to: LISTING_PATH,
    redirectId: true
  },
  {
    from: '/app/offices',
    to: DASHBOARD_PATH,
    exact: true
  }
];
