import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { app, media } from 'context';
import { Animate, IconButton, Layout, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { selectChatbotIsVisible, selectChatbotScreen, selectLatestChatbotThread } from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppSelector } from 'store/hooks';

import ChatbotAskButton from './ChatbotAskButton';
import ChatbotBottomNavigation from './ChatbotBottomNavigation';
import ChatbotHomePrompts from './ChatbotHomePrompts';
import ChatbotHomeRecentMessage from './ChatbotHomeRecentMessage';
import { CHATBOT_BOTTOM_NAVIGATION_HEIGHT, CHATBOT_DESKTOP_WIDTH } from './constants';
import useChatbotHeight from './useChatbotHeight';

import { ReactComponent as ChatbotHomeClouds } from './chatbot-home-clouds.svg';

interface Props {}

const TOP_CONTAINER_HEIGHT = 284;

const ChatbotHome: React.FC<Props> = () => {
  const { isMobile } = useContext(media);
  const { windowWidth } = useContext(app);
  const user = useAppSelector(selectUser);
  const latestThread = useAppSelector(selectLatestChatbotThread);
  const showRecentMessage = !!latestThread && !!latestThread.chatbot_interactions.length;
  const chatbotHeight = useChatbotHeight();
  const dispatch = useDispatch();
  const chatbotIsVisible = useAppSelector(selectChatbotIsVisible);
  const screen = useAppSelector(selectChatbotScreen);

  if (!user) return null;

  const componentsShouldAnimateIn = chatbotIsVisible && screen.useAnimation;

  return (
    <>
      <Layout
        {...(isMobile
          ? { height: '100%', direction: 'column', width: windowWidth }
          : {
              width: CHATBOT_DESKTOP_WIDTH,
              height: chatbotHeight - CHATBOT_BOTTOM_NAVIGATION_HEIGHT,
              overflowY: 'auto',
              overflowX: 'hidden',
              direction: 'column',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8
            })}
      >
        <Layout
          color="purple-gradient"
          paddingTop={8}
          paddingBottom={24}
          position="relative"
          height={TOP_CONTAINER_HEIGHT}
          flexShrink={0}
          {...(isMobile ? {} : { borderTopLeftRadius: 8, borderTopRightRadius: 8 })}
        >
          <Layout justify="flex-end" paddingX={16} position="relative" zIndex={1}>
            {isMobile ? (
              <IconButton
                size="lg"
                type="noBackground"
                name="close"
                onClick={() => dispatch(actions.setIsVisible(false))}
                iconColor="white"
              />
            ) : (
              <Layout width={48} height={48}></Layout>
            )}
          </Layout>
          <Animate
            paddingX={24}
            marginTop={44}
            position="relative"
            zIndex={1}
            animation="fade-up"
            duration={200}
            disableAnimation={!componentsShouldAnimateIn}
          >
            <Text size="headline-md" color="white">
              Hey, {user.firstname}.<br />
              How can I help?
            </Text>
            {!showRecentMessage && (
              <Layout marginTop={40}>
                <ChatbotAskButton />
              </Layout>
            )}
          </Animate>
          {showRecentMessage && (
            <Layout position="absolute" bottom={-104} paddingX={24} width="100%">
              <ChatbotHomeRecentMessage thread={latestThread} animateIn={componentsShouldAnimateIn} />
              <Layout marginTop={8}>
                <ChatbotAskButton animateIn={componentsShouldAnimateIn} />
              </Layout>
            </Layout>
          )}
          <ChatbotHomeClouds style={{ width: 789, height: 197, position: 'absolute', left: -188, top: 6, zIndex: 0 }} />
        </Layout>
        <Layout
          padding={24}
          color="blue-50"
          flexGrow={1}
          {...(isMobile ? { paddingBottom: CHATBOT_BOTTOM_NAVIGATION_HEIGHT + 24 } : {})}
        >
          <Layout {...(showRecentMessage ? { paddingTop: 106 } : {})}>
            <ChatbotHomePrompts animateIn={componentsShouldAnimateIn} />
          </Layout>
        </Layout>
      </Layout>
      {!isMobile && <ChatbotBottomNavigation />}
    </>
  );
};

export default ChatbotHome;
