import { ApiErrorResponse, EditableCheckinInstruction, SaveableCheckinInstruction } from '@codiwork/codi';
import _ from 'lodash';

export const isMobileDevice = (function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
})();

export function isGoogleUserAgent() {
  return window.navigator.userAgent.includes('Google');
}

export const hoursOffsetString = (hour: string) => {
  const offsetHours = new Date(hour).getTimezoneOffset() / 60;
  const plusMinus = offsetHours >= 0 ? '-' : '+';

  return plusMinus + ('0' + Math.abs(offsetHours)).slice(-2) + ':00';
};

export function objDifference(obj1: any, obj2: any) {
  return Object.keys(obj1).reduce((src: any, k) => {
    if (typeof obj1[k] === 'object' && !_.isEqual(obj1[k], obj2[k])) {
      src[k] = obj1[k];
    } else if (typeof obj1[k] === 'string' && obj1[k] !== obj2[k]) {
      src[k] = obj1[k];
    }
    return src;
  }, {});
}

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function arrayMove(array: Array<any>, from: number, to: number) {
  const newArr = array.slice();
  newArr.splice(to < 0 ? newArr.length + to : to, 0, newArr.splice(from, 1)[0]);
  return newArr;
}

export function dateObj(date: Date | string) {
  return typeof date === 'object' ? date : new Date(date);
}

export function addHour(date: Date, hour: number = 1) {
  date.setHours(date.getHours() + hour);
  return date;
}

// e.g. '$5,000' => 5000
export function moneyToInt(money: string): number {
  return parseInt(money.replaceAll('$', '').replaceAll(',', ''));
}

export const numberToUsd = (value: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(value);
};

export const toNumberValue = (value?: number | null | string) => {
  if (value === undefined || value === null) {
    return undefined;
  } else {
    return Number(value);
  }
};

// e.g. usd: $4,200.20 or $0.27
export const usdToNumber = (usd: string): number => {
  return Number(usd.replace('$', '').replaceAll(',', ''));
};

export function formatNumberAbbreviated(number: number) {
  let numberInThousands = number / 1000;

  if (numberInThousands < 1) {
    return (numberInThousands * 1000).toFixed(0);
  }

  let rounded = numberInThousands.toFixed(1);

  if (rounded.endsWith('.0')) {
    rounded = rounded.slice(0, -2);
  }

  return rounded + 'k';
}

export function formatMoney(
  amountInput: number,
  decimalCount: number = 2,
  decimal: string = '.',
  thousands: string = ','
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = Number(amountInput) < 0 ? '-' : '';
    let amount: string;

    let i: string = parseInt((amount = Math.abs(Number(amountInput) || 0).toFixed(decimalCount))).toString();
    let j: number = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(Number(amount) - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    return '';
  }
}

const usdFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const usdFormatRounded = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
});

export function formatUsd(amount: number, cents: boolean = true) {
  const format = cents ? usdFormat : usdFormatRounded;
  return format.format(amount);
}

export const extractErrorMessage = (error: ApiErrorResponse): string => {
  if (typeof error.data === 'string') {
    return error.data;
  } else {
    return Object.values(error.data)[0][0];
  }
};

export function camelCaseToTitleCase(text: string): string {
  const separateWords = text.replace(/([A-Z])/g, ' $1');
  return separateWords.charAt(0).toUpperCase() + separateWords.slice(1);
}

export function areSaveableCheckinInstructions(
  instructions: EditableCheckinInstruction[]
): instructions is SaveableCheckinInstruction[] {
  return instructions.every(i => !!i.description && !!i.photo_url && typeof i.sequence === 'number');
}

export function isCodiEmail(email: string) {
  return email.split('@')[1] === 'codi.com';
}
