import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { app, media } from 'context';
import { Clickable, HEADER_Z_INDEX, Icon, Layout } from 'ds';
import { CUSTOMER_SUPPORT_PATH } from 'routes';
import { actions } from 'store/Chatbot';
import { selectChatbotIsVisible } from 'store/Chatbot/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CHATBOT_BUTTON_DIMENSION, CHATBOT_HOME_SCREEN } from './constants';

interface Props {}

const PATHS_WITH_BOTTOM_CTA = [CUSTOMER_SUPPORT_PATH];

const ChatbotButton: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const isVisible = useAppSelector(selectChatbotIsVisible);
  const { bottomNavigationHeight } = useContext(app);
  const { isMobile } = useContext(media);
  const { pathname } = useLocation();

  const onPageWithBottomCTA = PATHS_WITH_BOTTOM_CTA.includes(pathname as typeof PATHS_WITH_BOTTOM_CTA[number]);

  return (
    <Layout
      zIndex={HEADER_Z_INDEX + 5}
      position="fixed"
      right={24}
      bottom={isMobile ? 24 + bottomNavigationHeight + (onPageWithBottomCTA ? 64 : 0) : 24}
      transition="bottom 150ms ease-in-out"
      display="inline-flex"
    >
      <Clickable
        onClick={e => {
          e.stopPropagation();
          dispatch(actions.pushScreen({ useAnimation: true, value: CHATBOT_HOME_SCREEN }));
          dispatch(actions.setIsVisible(!isVisible));
        }}
      >
        <Layout
          color="purple-gradient"
          align="center"
          justify="center"
          borderRadius="circular"
          width={CHATBOT_BUTTON_DIMENSION}
          height={CHATBOT_BUTTON_DIMENSION}
        >
          <Layout
            __className={classNames('ChatbotButton-icon', { 'animate-in': isVisible && !isMobile })}
            display="inline-flex"
          >
            <Icon name={isVisible && !isMobile ? 'downChevron' : 'sparkle'} color="white" size={24} />
          </Layout>
        </Layout>
      </Clickable>
    </Layout>
  );
};

export default ChatbotButton;
