import { CHATBOT_THREADS_SCREEN, GREETING_VARIATIONS } from './constants';
import { ChatbotInteraction } from './types';

export function parseChatbotThreadId(screen: string) {
  if (!screen.startsWith(`${CHATBOT_THREADS_SCREEN}/`)) return null;

  const rawId = screen.split('/')[1];

  return parseInt(rawId);
}

export function getGreetingVariation(name: string) {
  const randomIndex = Math.floor(Math.random() * GREETING_VARIATIONS.length);
  return GREETING_VARIATIONS[randomIndex].replace('{{name}}', name);
}

export function shouldAnimateIn({ created_at }: ChatbotInteraction) {
  return Date.now() - new Date(created_at).getTime() < 2_000;
}
