import React from 'react';

import { Animate, Clickable, Grid, Icon, Layout, Text } from 'ds';
import { actions } from 'store/Chatbot';
import { useAppDispatch } from 'store/hooks';

import { CHATBOT_THREADS_SCREEN } from './constants';
import { determineIntent, startThread } from './requests';

interface Props {
  animateIn?: boolean;
}

export const PROMPTS = [
  { value: 'I need to order an extra cleaning' },
  { value: 'Change the day of my weekly cleaning' },
  { value: "I'd like to order standing desks" }
];

const ChatbotHomePrompts: React.FC<Props> = ({ animateIn }) => {
  const dispatch = useAppDispatch();

  const handleClick = async ({ value }: { value: string }) => {
    const { data: thread } = await startThread();
    const { id: threadId } = thread;

    dispatch(actions.addThread(thread));
    dispatch(actions.addPendingThreadInteraction({ threadId, input: value }));
    determineIntent({ chatbotThreadId: thread.id, message: value }).then(({ data }) => {
      dispatch(actions.removePendingThreadInteraction({ threadId }));
      dispatch(actions.addInteraction({ interaction: data, threadId }));
    });
    dispatch(actions.pushScreen({ value: `${CHATBOT_THREADS_SCREEN}/${threadId}`, spawnedFromHomePrompt: true }));
  };

  return (
    <>
      <Animate duration={300} delay={60} animation="fade-up" disableAnimation={!animateIn}>
        <Text size="body-md" semibold>
          Here are more ways I can help
        </Text>
      </Animate>
      <Layout marginTop={24}>
        <Grid gapY={12} itemsPerRow={1}>
          {PROMPTS.map(({ value }, index) => {
            return (
              <Clickable
                key={value}
                onClick={() => {
                  handleClick({ value });
                }}
              >
                <Animate
                  color="blue-100"
                  paddingLeft={24}
                  paddingRight={16}
                  borderRadius={1000}
                  justify="space-between"
                  align="center"
                  paddingY={16}
                  duration={300}
                  delay={60 + index * Math.pow(4, 2.3)}
                  disableAnimation={!animateIn}
                >
                  <Text size="body-sm" color="blue-500" semibold>
                    {value}
                  </Text>
                  <Icon stroke={2} name="rightChevron" color="blue-500" size="xs" />
                </Animate>
              </Clickable>
            );
          })}
        </Grid>
      </Layout>
    </>
  );
};

export default ChatbotHomePrompts;
