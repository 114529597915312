import React, { useContext, useEffect, useRef, useState } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import { Animate, CHATBOT_THREAD_INPUT_HEIGHT, Layout, SCROLLABLE_BOX_SHADOW, ScrollShadowContainer } from 'ds';
import usePageVisibility from 'hooks/usePageVisibility';
import { actions } from 'store/Chatbot';
import {
  selectActiveChatbotThread,
  selectChatbotPreviousScreen,
  selectChatbotScreen,
  selectPendingThreadInteraction
} from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createOrUpdateSupportTicketView } from 'ux/Customer/Support/requests';

import ChatbotInteractionDisplay from './ChatbotInteractionDisplay';
import ChatbotNavigationHeader from './ChatbotNavigationHeader';
import ChatbotThinkingDisplay from './ChatbotThinkingDisplay';
import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from './ChatbotThreadMessage';
import CodiAIAvatar from './CodiAIAvatar';
import CustomerChatbotThreadInput from './CustomerChatbotThreadInput';
import { CHATBOT_HOME_SCREEN, CHATBOT_NAVIGATION_HEADER_HEIGHT } from './constants';
import { ChatbotThread as ChatbotThreadType } from './types';
import useChatbotHeight from './useChatbotHeight';
import { getGreetingVariation } from './utils';

interface Props {
  bottomSheetRef?: React.RefObject<HTMLDivElement>;
}

const THREAD_ID_TO_GREETING_VARIATION = new Map<number, string>();

const ChatbotThread: React.FC<Props> = ({ bottomSheetRef }) => {
  const user = useAppSelector(selectUser);
  const thread = useAppSelector(selectActiveChatbotThread);
  const threadId = thread?.id || -1;
  let greetingVariation = THREAD_ID_TO_GREETING_VARIATION.get(threadId);
  if (!greetingVariation) {
    greetingVariation = getGreetingVariation(user?.firstname || '');
    THREAD_ID_TO_GREETING_VARIATION.set(threadId, greetingVariation);
  }
  const screen = useAppSelector(selectChatbotScreen);

  const { isMobile } = useContext(media);
  const pendingInteraction = useAppSelector(selectPendingThreadInteraction(threadId));
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const previousScreen = useAppSelector(selectChatbotPreviousScreen);
  const height = useChatbotHeight();
  const supportTicket = lastInteraction?.support_ticket;
  const updateKey =
    (thread
      ? Math.max(...thread.chatbot_interactions.map(i => DateTime.fromISO(i.updated_at).toMillis()))
      : 0
    ).toString() +
    (pendingInteraction ? 'isPending' : '') +
    lastInteraction?.support_ticket?.messages.length;

  const handleSupportTicketView = async () => {
    if (supportTicket?.id) {
      const { data } = await createOrUpdateSupportTicketView({ supportTicketId: supportTicket.id });

      dispatch(actions.updateSupportTicket(data));
    }
  };

  const onCreate = (chatbotThread: ChatbotThreadType) => {
    if (!greetingVariation) return;
    THREAD_ID_TO_GREETING_VARIATION.set(chatbotThread.id, greetingVariation);
    THREAD_ID_TO_GREETING_VARIATION.delete(-1);
  };

  usePageVisibility(isVisible => {
    if (isVisible) {
      handleSupportTicketView();
    }
  });

  const scrollToBottom = () => {
    const container = isMobile ? bottomSheetRef?.current : containerRef.current;

    if (!container) return;

    container.scrollTo({ top: container.scrollHeight });
  };

  useEffect(() => {
    scrollToBottom();
  }, [updateKey]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!supportTicket?.id) return;

    handleSupportTicketView();
  }, [supportTicket?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (threadId === -1) {
        THREAD_ID_TO_GREETING_VARIATION.delete(threadId);
      }
    };
  }, [threadId]);

  const dispatch = useAppDispatch();

  if (!user) return null;

  const header = (
    <ChatbotNavigationHeader backScreenValue={previousScreen.value || CHATBOT_HOME_SCREEN}>
      <CodiAIAvatar />
    </ChatbotNavigationHeader>
  );

  return (
    <>
      {isMobile ? (
        <Layout
          position="sticky"
          top={0}
          zIndex={1}
          color="blue-50"
          height={CHATBOT_NAVIGATION_HEADER_HEIGHT}
          overflow="hidden"
        >
          {header}
        </Layout>
      ) : (
        <Layout
          color="blue-50"
          borderTopLeftRadius={8}
          borderTopRightRadius={8}
          height={CHATBOT_NAVIGATION_HEADER_HEIGHT}
          overflow="hidden"
          boxShadow={hasScrolled ? SCROLLABLE_BOX_SHADOW : undefined}
          zIndex={1}
        >
          {header}
        </Layout>
      )}
      <ScrollShadowContainer
        setHasScrolled={setHasScrolled}
        position="relative"
        color="blue-50"
        paddingX={24}
        __ref={containerRef}
        {...(isMobile
          ? { paddingTop: 12 }
          : {
              height: height - CHATBOT_NAVIGATION_HEADER_HEIGHT - CHATBOT_THREAD_INPUT_HEIGHT,
              overflowY: 'scroll',
              paddingBottom: 16
            })}
      >
        <Animate
          marginRight={24}
          marginTop={16}
          disableAnimation={!!thread?.chatbot_interactions.length}
          duration={300}
          delay={400}
        >
          <ChatbotThreadMessage isLastMessageInGroup>{greetingVariation}</ChatbotThreadMessage>
        </Animate>
        <Layout>
          {thread?.chatbot_interactions.map(ci => {
            return <ChatbotInteractionDisplay key={ci.id} chatbotInteraction={ci} threadId={threadId} />;
          })}
          {!!pendingInteraction && (
            <Animate
              marginTop={16}
              marginBottom={16}
              animation="fade-up"
              duration={300}
              marginLeft={24}
              delay={screen.spawnedFromHomePrompt ? 500 : 0}
              justify="flex-end"
            >
              <ChatbotThreadCustomerMessage isLastMessageInGroup>
                {pendingInteraction.input}
              </ChatbotThreadCustomerMessage>
            </Animate>
          )}
          {!!pendingInteraction && (
            <Animate marginTop={16} delay={screen.spawnedFromHomePrompt ? 600 : 300} duration={300}>
              <ChatbotThinkingDisplay />
            </Animate>
          )}
        </Layout>
      </ScrollShadowContainer>
      {!isMobile && <CustomerChatbotThreadInput onCreate={onCreate} />}
    </>
  );
};

export default ChatbotThread;
