import React, { useRef, useState } from 'react';

import { showSnackbar } from 'helpers/snackbar';

import { ChatbotThreadInput, RequestState } from 'ds';
import { actions } from 'store/Chatbot';
import { selectActiveChatbotThread, selectThreadInputs } from 'store/Chatbot/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { CHATBOT_THREADS_SCREEN } from './constants';
import { determineIntent, startThread, updateIntent } from './requests';
import { ChatbotThread } from './types';

interface Props {
  onCreate?: (chatbotThread: ChatbotThread) => void;
}

const CustomerChatbotThreadInput: React.FC<Props> = ({ onCreate }) => {
  const user = useAppSelector(selectUser);
  const thread = useAppSelector(selectActiveChatbotThread);
  const disableAnimation = !!thread?.chatbot_interactions.length;
  const threadId = thread?.id || -1;
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const threadInputs = useAppSelector(selectThreadInputs);
  const threadInput = threadInputs.find(i => i.threadId === threadId);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useAppDispatch();

  const setInput = (input: string) => {
    dispatch(actions.setThreadInput({ input, threadId }));
  };

  if (!user) return null;

  // TODO: does this still happen?
  const isConversationMode = !!lastInteraction?.llm_conversation;

  const handleInteraction = async ({ threadId, input }: { threadId: number; input: string }) => {
    if (isConversationMode) {
      const { data: convoInteraction } = await updateIntent({
        chatbot_interaction_id: lastInteraction.id,
        slots: { ...lastInteraction.slots, message: input }
      });
      dispatch(actions.removePendingThreadInteraction({ threadId }));
      dispatch(actions.updateInteraction({ interaction: convoInteraction, threadId: threadId }));
    }

    const { data } = await determineIntent({ chatbotThreadId: threadId, message: input });
    dispatch(actions.removePendingThreadInteraction({ threadId }));
    dispatch(actions.addInteraction({ interaction: data, threadId }));
  };

  const handleSubmit = async () => {
    if (!threadInput || !threadInput.input || requestState === 'in_progress') return;

    setRequestState('in_progress');
    // onRequestStateChange('in_progress');

    try {
      if (thread) {
        dispatch(actions.addPendingThreadInteraction({ threadId: thread.id, input: threadInput.input }));
        setInput('');
        await handleInteraction({ threadId: thread.id, input: threadInput.input });
      } else {
        const { data } = await startThread();
        onCreate?.(data);
        dispatch(actions.addThread(data));
        dispatch(actions.addPendingThreadInteraction({ threadId: data.id, input: threadInput.input }));
        setInput('');
        handleInteraction({ threadId: data.id, input: threadInput.input });
        dispatch(actions.replaceScreen({ value: `${CHATBOT_THREADS_SCREEN}/${data.id}` }));
      }
    } catch (error) {
      console.error(error);
      showSnackbar({ negative: true });
    } finally {
      setRequestState('ready');
      // onRequestStateChange('ready');
    }
  };

  return (
    <ChatbotThreadInput
      input={threadInput?.input || ''}
      onChange={input => setInput(input)}
      handleSubmit={handleSubmit}
      disabled={requestState === 'in_progress'}
      ref={inputRef}
      disableAnimation={disableAnimation}
    />
  );
};

export default CustomerChatbotThreadInput;
