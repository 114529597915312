import React from 'react';

import { Layout } from 'ds';

import ChatbotThreadMessage from './ChatbotThreadMessage';

interface Props {}

const DOT_SIZE = 6;

const ChatbotThinkingDisplay: React.FC<Props> = () => {
  return (
    <ChatbotThreadMessage isLastMessageInGroup>
      <Layout gap={4} flex>
        <Layout
          __className="Chatbot-chatbotThinkingDisplayDot"
          color="gray-100"
          width={DOT_SIZE}
          height={DOT_SIZE}
          borderRadius="circular"
        />
        <Layout
          __className="Chatbot-chatbotThinkingDisplayDot"
          color="gray-100"
          width={DOT_SIZE}
          height={DOT_SIZE}
          borderRadius="circular"
        />
        <Layout
          __className="Chatbot-chatbotThinkingDisplayDot"
          color="gray-100"
          width={DOT_SIZE}
          height={DOT_SIZE}
          borderRadius="circular"
        />
      </Layout>
    </ChatbotThreadMessage>
  );
};

export default ChatbotThinkingDisplay;
