import React from 'react';

import { Layout } from 'ds';
import { selectCustomerSupportTicketByInteractionId } from 'store/Chatbot/selectors';
import { useAppSelector } from 'store/hooks';
import { ChatbotIntentProps } from 'ux/Chatbot/types';
import { shouldAnimateIn } from 'ux/Chatbot/utils';

import ChatbotCreateSupportTicket from '../ChatbotCreateSupportTicket';
import ChatbotViewSupportTicket from '../ChatbotViewSupportTicket';
import { LLMResponse } from '../LLMResponse';

interface Props extends ChatbotIntentProps {
  askToCreateSupportTicket?: boolean;
}

const Fallback: React.FC<Props> = ({ chatbotInteraction, threadId, askToCreateSupportTicket }) => {
  const supportTicket = useAppSelector(selectCustomerSupportTicketByInteractionId(chatbotInteraction.id));
  const { llm_response, resolved_at } = chatbotInteraction;

  const animateIn = shouldAnimateIn(chatbotInteraction);

  return (
    <>
      <LLMResponse llmResponse={llm_response} animateIn={animateIn} />
      {askToCreateSupportTicket &&
        (resolved_at && supportTicket ? (
          <Layout marginTop={16}>
            <ChatbotViewSupportTicket supportTicket={supportTicket} />
          </Layout>
        ) : (
          <Layout marginTop={16}>
            <ChatbotCreateSupportTicket
              chatbotInteraction={chatbotInteraction}
              threadId={threadId}
              animateIn={animateIn}
              animationDelay={300}
            />
          </Layout>
        ))}
    </>
  );
};

export default Fallback;
