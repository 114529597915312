import {
  Cart,
  CustomerUserWorkspace,
  OfficeLead,
  Offsite,
  Payout,
  PayoutAccount,
  Updatable,
  User,
  UserCalendar,
  VendorTask,
  Workspace,
  safeAssign,
  updateInList
} from '@codiwork/codi';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';

import { ReferralInvite } from 'ux/Customer/Referrals/types';
import { CustomerRequest } from 'ux/Dashboard/CustomerRequests/types';
import { CustomerAdminReservation } from 'ux/Dashboard/types';
import { ListPayment, UpcomingPayment } from 'ux/Payments/types';

type UserState = {
  user: User | undefined | null;
  calendar: UserCalendar | undefined | null;
  availableWorkspaces: null | CustomerUserWorkspace[];
  payoutAccount: null | PayoutAccount;
  payouts: Payout[] | null;
  payments: ListPayment[] | null;
  reservations: CustomerAdminReservation[] | null;
  upcomingPayments: UpcomingPayment[] | null;
  requests: CustomerRequest[];
  referralInvites: ReferralInvite[];
};

export const USER_INITIAL_STATE: UserState = {
  user: undefined,
  calendar: undefined,
  availableWorkspaces: null,
  payoutAccount: null,
  payouts: null,
  payments: null,
  upcomingPayments: null,
  reservations: null,
  requests: [],
  referralInvites: []
};

const userSlice = createSlice({
  name: 'User',
  initialState: USER_INITIAL_STATE,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      const user = action.payload;
      Sentry.setUser(
        user ? { email: user.email, id: user.id.toString(), username: `${user.firstname} ${user.lastname}` } : null
      );
      state.user = user;
    },
    setUserCalendar: (state, action: PayloadAction<UserCalendar | null>) => {
      state.calendar = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      safeAssign(state.user, action.payload);
    },
    updateListingAdminWorkspace: (state, { payload }: PayloadAction<Workspace>) => {
      if (!!state.user?.listing_admin_workspaces) {
        const workspace = state.user.listing_admin_workspaces.find(workspace => workspace.id === payload.id);
        if (!!workspace) {
          safeAssign(workspace, payload);
        } else {
          safeAssign(state.user.listing_admin_workspaces, [payload, ...state.user.listing_admin_workspaces]);
        }
      }
    },
    updateVendorTaskById: (state, { payload }: PayloadAction<Updatable<VendorTask>>) => {
      if (!!state.user) {
        updateInList(state.user.assigned_tasks, payload);
      }
    },
    setAvailableWorkspaces: (state, { payload }: PayloadAction<CustomerUserWorkspace[]>) => {
      const { availableWorkspaces } = state;

      if (!availableWorkspaces) {
        state.availableWorkspaces = payload;
        return;
      }

      payload.forEach(workspace => {
        const existingWorkspace = availableWorkspaces.find(({ id }) => workspace.id === id);

        if (existingWorkspace) {
          updateInList(availableWorkspaces, workspace);
        } else {
          availableWorkspaces.push(workspace);
        }
      });
    },
    setPayoutAccount: (state, action: PayloadAction<PayoutAccount | null>) => {
      state.payoutAccount = action.payload;
    },
    setPayouts: (state, action: PayloadAction<Payout[] | null>) => {
      state.payouts = action.payload;
    },
    setPayments: (state, action: PayloadAction<ListPayment[] | null>) => {
      state.payments = action.payload;
    },
    setUpcomingPayments: (state, action: PayloadAction<UpcomingPayment[] | null>) => {
      state.upcomingPayments = action.payload;
    },
    setReservations: (state, action: PayloadAction<CustomerAdminReservation[] | null>) => {
      state.reservations = action.payload;
    },
    setCustomerRequests: (state, action: PayloadAction<CustomerRequest[]>) => {
      state.requests = action.payload;
    },
    addCustomerRequest: (state, { payload }: PayloadAction<CustomerRequest>) => {
      state.requests.push(payload);
    },
    removeRequest: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.requests = state.requests.filter(r => r.id !== payload.id);
    },
    setReferralInvites: (state, { payload }: PayloadAction<ReferralInvite[]>) => {
      state.referralInvites = payload;
    },
    updateOfficeLead: (state, { payload }: PayloadAction<Updatable<OfficeLead>>) => {
      if (!state.user) return;

      updateInList(state.user.office_leads, payload);
    },
    removeOfficeLead: (state, { payload }: PayloadAction<{ id: number }>) => {
      if (!state.user) return;

      state.user.office_leads = state.user.office_leads.filter(officeLead => officeLead.id !== payload.id);
    },
    updateActiveFeatures: (state, { payload }: PayloadAction<string[]>) => {
      if (!state.user) return;

      state.user.active_features = payload;
    },
    updateOffsite: (state, { payload }: PayloadAction<Updatable<Offsite>>) => {
      if (!state.user) return;

      updateInList(state.user.offsites, payload);
    },
    deleteOffsite: (state, { payload }: PayloadAction<{ id: number }>) => {
      if (!state.user) return;

      state.user.offsites = state.user.offsites.filter(offsite => offsite.id !== payload.id);
    },
    setCart: (state, { payload }: PayloadAction<{ cart: Cart | null; plan_id: number }>) => {
      if (!state.user) return;

      let plan = state.user.customer_admin_plans?.long_term_plans.find(plan => plan.id === payload.plan_id);

      if (!plan) return;
      plan.cart = payload.cart;
    },
    updateCartItemQuantity: (
      state,
      { payload }: PayloadAction<{ cartItemId: number; quantity: number; plan_id: number }>
    ) => {
      if (!state.user) return;

      let plan = state.user.customer_admin_plans?.long_term_plans.find(plan => plan.id === payload.plan_id);

      if (!plan) return;
      if (!plan.cart || plan.cart.items.length < 0) return;

      let cartItem = plan.cart.items.find(item => item.id === payload.cartItemId);
      if (!cartItem) return;
      cartItem.quantity = payload.quantity;
    }
  }
});

export const { actions } = userSlice;

export default userSlice.reducer;
