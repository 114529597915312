import React from 'react';

import Layout from 'ds/Layout';
import Text from 'ds/Text';
import { AdminTaskStatus } from 'ux/Admin/Tasks/types';

interface Props {
  status: AdminTaskStatus;
  alignDotLeft?: boolean;
}

const TaskStatus: React.FC<Props> = ({ status, alignDotLeft }) => {
  switch (status) {
    case 'open':
      return (
        <Layout
          color="gray-50"
          align="center"
          paddingX={12}
          paddingY={6}
          borderRadius={4}
          direction={alignDotLeft ? 'row-reverse' : undefined}
        >
          <Layout marginLeft={alignDotLeft ? 12 : undefined}>
            <Text size="body3" semibold>
              Open
            </Text>
          </Layout>
          <Layout
            width={8}
            height={8}
            marginLeft={alignDotLeft ? undefined : 12}
            borderRadius="circular"
            color="gray-600"
          />
        </Layout>
      );
    case 'scheduled':
      return (
        <Layout
          color="blue-100"
          align="center"
          paddingX={12}
          paddingY={6}
          borderRadius={4}
          direction={alignDotLeft ? 'row-reverse' : undefined}
        >
          <Layout marginLeft={alignDotLeft ? 12 : undefined}>
            <Text size="body3" color="blue-500" semibold>
              Scheduled
            </Text>
          </Layout>
          <Layout
            width={8}
            height={8}
            marginLeft={alignDotLeft ? undefined : 12}
            borderRadius="circular"
            color="blue-500"
          />
        </Layout>
      );
    case 'done':
      return (
        <Layout
          color="green-100"
          align="center"
          paddingX={12}
          paddingY={6}
          borderRadius={4}
          direction={alignDotLeft ? 'row-reverse' : undefined}
        >
          <Layout marginLeft={alignDotLeft ? 12 : undefined}>
            <Text size="body3" color="green-700" semibold>
              Done
            </Text>
          </Layout>
          <Layout
            width={8}
            height={8}
            marginLeft={alignDotLeft ? undefined : 12}
            borderRadius="circular"
            color="green-700"
          />
        </Layout>
      );
    case 'canceled':
      return (
        <Layout
          color="gray-25"
          align="center"
          paddingX={12}
          paddingY={6}
          borderRadius={4}
          direction={alignDotLeft ? 'row-reverse' : undefined}
        >
          <Layout marginLeft={alignDotLeft ? 12 : undefined}>
            <Text size="body3" semibold>
              Canceled
            </Text>
          </Layout>
          <Layout
            width={7}
            height={7}
            borderWidth={1}
            borderColor="gray-400"
            marginLeft={alignDotLeft ? undefined : 12}
            borderRadius="circular"
          />
        </Layout>
      );
  }
};

export default TaskStatus;
